import React from 'react';

const LeakInfo=(props)=>{
	const leaked=props.leak.map((leak)=>
		{
			return <div className="ui label" style={{'left':'10px','margin':'3px'}} >{leak}</div>;
		});
	return <div>{leaked}</div>;
}

export default LeakInfo;