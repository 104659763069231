import React from 'react';

const Result=(props)=>
{
	
	const fsize=window.innerWidth >800? '20px':'15px';
	return (	
<div className="ui big label" style={{'marginTop':'15px','fontSize':`${fsize}`,'boxShadow':'0px 0px 6px 0.3px rgba(166,166,166,1)'}}> Found {props.len.length} Breaches</div> );
};


export default Result;
