import React from 'react';
import data from './biggest.json'


const Biggestbreach=()=>
{
	const leak=data.map((mydata)=>{

return(<div className="ui middle aligned divided list">
  <div className="item">
    <div className="right floated content">
      <a key="1" href={mydata.url} rel="noopener noreferrer" target="_blank"  className="ui button">View</a>
    </div>
    <img alt="breach" className="ui avatar image" src={mydata.info}/>
    <div className="content" style={{'fontWeight':'bold','color':'#686868'}}>
     <div style={{'fontWeight':'bold','color':'#686868','fontSize':'20px','marginBottom':'6px'}}> {mydata.name}</div>
     <div style={{'color':'#686868','fontSize':'15px'}}>  {mydata.record} user data leaked.</div>  
    </div>
  </div>
  
  
  <div className="ui divider"></div>
  </div>);


});
	
	return <div>{leak}</div>;
}


export default Biggestbreach;