import React from 'react';
import data from './data.json';
import LeakInfo from './LeakInfo';
import DestopLink from './DestopLink';
import MobileLink from './MobileLink';
import Accordion from './Accordian';
const Post= props =>
{
	const finalresult = data.map((mydata)=> {
 
		if(props.result.Name === mydata.name)
		{
		return (

<div id='contained' key={mydata.id} className="ui very relaxed items" >

		<div className="item" style={{ 
  'borderRadius': '10px','margin':'10px', 'backgroundColor':'#cc6161','boxShadow':'0px 0px 8px 1px rgba(166,166,166,1)'}}>
			<div className="ui small image" style={{'display':'block','padding': '20px'}}>
				<img alt={props.result.Name} src={props.result.LogoPath} />
			</div>
		<div className="content"  style={{'paddingLeft': '10px','paddingRight':'10px'}}>

		 <div style={{'fontSize':'25px','fontFamily':'Roboto','color':'white','fontWeight':'bold','paddingTop': '10px'}}> {props.result.Name}</div>
			<div className="meta"  >
			 <span style={{'fontSize':'21px','fontFamily':'Roboto','color': 'white'}}>Breached On :{props.result.BreachDate}</span>
			</div>
<div className="description" >
			<span style={{'fontSize':'21px','fontFamily':'Roboto','color': 'white'}}>No. of user-data leaked : {props.result.PwnCount}</span>
			</div>
			<div className="description">

			<Accordion des={props.result.Description}/>
	
			</div>
			<br></br>
			<div ><LeakInfo leak={props.result.DataClasses} /></div>
			<div className="extra" >
			{window.innerWidth < 800 ?<MobileLink name={mydata.name}url={mydata.murl}/> : <DestopLink name={mydata.name} url={mydata.durl}/>}
		</div>

				<br></br>
			</div>
			</div>
			</div>

				);
		}

});

return (<div >

<div>{finalresult}</div>

</div>);	
};

export default Post;