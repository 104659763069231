import React from 'react';
import ReactDom from 'react-dom';
import App from './components/App';
import Why from './components/why';
import LatestInfo from './components/LatestInfo';
const start=()=>
{
	return <App />;

};
const start1=()=>
{
	return <Why />;

};
const start2=()=>
{
	return <LatestInfo />;
}


ReactDom.render(<Why />,document.querySelector('#whyus'));
ReactDom.render(<App />,document.querySelector('#root'));
ReactDom.render(<LatestInfo />,document.querySelector('#info'));
