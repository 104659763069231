import React from 'react';

const Stat1=()=>
{

return(

	<div style={{'backgroundColor':'white'}} className="ui " >
	<div className='ui one small float statistics'>
  <div className="statistic">
    <div style={{'fontSize':'25px'}} className="value">
      445+
    </div>
    <div className="label">
      Websites Breached
    </div>
        
  </div>
  
  <div className="statistic">
    <div  style={{'fontSize':'20px'}} className="value">
       3 Million
    </div>
    <div className="label">
    Accounts Breached Everyday
    </div>
 

  </div>
  <div className="statistic">
    <div  className="value">
      4 Billion
    </div>
    <div className="label">
      Accounts Breached
    </div>
    

  </div>
  
</div>

</div>
);



};
export default Stat1;