import React from 'react';
import styles from '../css/loader.css'

const Loader=()=>
{
	return (<div className="loader"></div>);
};


export default Loader;
