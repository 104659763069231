import React from 'react';
import Latestleak from './Latestleak';
import Biggestbreach from './Biggestbreach';

const LatestInfo=()=>
{
return(<div>
   <br></br>
	<div style={{'marginTop':'30px','fontSize':'25px','fontWeight':'bold','textAlign':'center','color':'brown'}}>Who All Took The Fall</div>
	<hr style={{'align':'center','width':'8%','marginTop':'20px','marginBottom':'15px',  'border': '1px solid brown','backgroundColor':'brown','color':'brown'}}></hr>
	<br></br>
	
	<div className="ui placeholder segment" style={{'boxShadow':'0px 0px 4px 1px rgba(166,166,166,1)'}}>
  <div className="ui two column very relaxed stackable grid">
    <div className="column">
    <p style={{'textAlign':'center','fontWeight':'bold','color':'#686868','fontSize':'23px'}}>Latest Data Leak </p>
   <Latestleak />
    </div>
    <div className="middle aligned column">
    <p style={{'textAlign':'center','fontWeight':'bold','color':'#686868','fontSize':'23px'}}>Biggest Data Leak </p>
   <Biggestbreach />
  </div>
  </div>
 
</div>
<br></br>
 <br></br>
</div>

);
}





export default LatestInfo;