import React from 'react';

const Why2=()=>{
return(<div>
<br></br>
	<div style={{'fontSize':'25px','fontWeight':'bold','textAlign':'center','marginTop':'15px','color':'#2a938c'}}>Why Use us?</div>
			<hr style={{'align':'center','width':'8%','marginTop':'20px','marginBottom':'15px',  'border': '1px solid #2a938c','backgroundColor':'#2a938c','color':'#2a938c'}}></hr>

	<br></br>

	<div className="ui verical segments" style={{'backgroundColor':'white','boxShadow':'0px 0px 3px 1px rgba(166,166,166,1)'}}>
  
  <div className="ui center aligned segment">
    <div className="text value">
 <i className="huge check squared grey icon" style={{'marginTop':'15px'}}></i>
     </div>
    <div className="ui label" style={{'backgroundColor':'white','color':'black','fontSize':'23px','fontWeight':'bold','fontFamily':'serif'}}>
     Check for email breach 
    </div>
    <div style={{'fontSize':'15px','width':'100%','float':'center','fontFamily':'sans-serif'}} >
    You just have to enter your email and let us do the magic for you. We will search through 4 billion records and display accurate breached results. 

  
  </div>
 
 </div>
  <div className="ui center aligned segment">
    <div className="text value">
 <i className="huge edit squared grey icon" style={{'marginTop':'15px'}}></i>
     </div>
    <div className="ui" style={{'backgroundColor':'white','color':'black','fontSize':'23px','fontWeight':'bold','textAlign':'center','marginTop':'15px','marginBottom':'15px','fontFamily':'serif'}}>
     Secure breached accounts instantly
    </div>
    <div style={{'fontSize':'15px','width':'100%','float':'center','marginBottom':'20px','fontFamily':'sans-serif'}} >
   After Finding the results, You can secure each breached account instantly without any hassle. Its just a click away.   </div>

  
  </div>

  <div className="ui center aligned segment">
    <div className="text value">
 <i className="huge sitemap squared grey icon" style={{'marginTop':'15px'}}></i>
     </div>
    <div className="ui label" style={{'backgroundColor':'white','color':'black','fontSize':'22px','fontWeight':'bold','fontFamily':'serif'}}>
     Secure all your accounts
    </div>
    <div style={{'fontSize':'15px','width':'100%','textAlign':'center','fontFamily':'sans-serif'}} >
If you have been using the same words everywhere, We have a solution for you. Our directory feature lets you secure all other accounts instantly.
    </div>
  </div>
  </div>
  <br></br>


  </div> );


};
export default Why2;