import React from 'react';
import data from './data.json'


const DestopLink=(props)=>{



return(<div>
      
 <div>
  <a href={props.url} data-tooltip={props.url} style={{'backgroundColor':'#32CD32','margin':'auto','fontWeight':'bold','fontSize':'17px' ,'fontFamily':'lato','backgroundShadow':'0px 0px 8px 1px rgba(166,166,166,1)'}}  rel="noopener noreferrer" target="_blank"class="ui right floated primary button">
        
       Secure {props.name} Account Instantly
          <i className="right chevron icon"></i>
        </a>
        </div>
      
      </div>  
      

);
};
 export default DestopLink;
