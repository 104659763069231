import React from 'react';

import Why1 from './why1';
import Why2 from './why2'

const Why=()=>
{

return(<div>{window.innerWidth >800 ? <Why2 />:<Why1 />} </div>);

};

export default Why;