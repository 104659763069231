import React from 'react';

class BuyMeACoffee extends React.Component {
  constructor(props) {
    super(props);
    
    // Create the script element with the required attributes
    let script = document.createElement("script");
    script.setAttribute('data-name', 'BMC-Widget');
    script.src = "https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js";
    script.setAttribute('data-id', 'boulderproblems');  // Replace with your own ID
    script.setAttribute('data-description', 'Thank you for your support!');
    script.setAttribute('data-message', 'This web is free to use. Do you want to help supporting it?');
    script.setAttribute('data-color', "#FF5F5F");
    script.setAttribute('data-position', 'right');
    script.setAttribute('data-x_margin', '18');
    script.setAttribute('data-y-margin', '18');
    script.async = true;

    // Ensure the widget displays correctly when the script loads
    script.onload = function () {
      var evt = document.createEvent('Event');
      evt.initEvent('DOMContentLoaded', false, false);
      window.dispatchEvent(evt);
    }

    this.script = script;
  }

  componentDidMount() {
    // Append the script to the head when the component mounts
    document.head.appendChild(this.script);
  }

  componentWillUnmount() {
    // Remove the script and the widget button when the component unmounts
    document.head.removeChild(this.script);
    const widgetButton = document.getElementById("bmc-wbtn");
    if (widgetButton) {
      document.body.removeChild(widgetButton);
    }
  }

  render() {
    return null;
  }
}

export default BuyMeACoffee;
