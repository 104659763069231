import React from 'react';

import styles from '../css/styles.css'

class Searchbar extends React.Component{
state = { term : '' };
onFormSubmit = (event)=>{
	event.preventDefault();
	this.props.onSubmit(this.state.term);
};
render(){


const size=window.innerWidth >800? '60px':'40px';

const fsize=window.innerWidth >800? '20px':'15px';
return (
	<div >
	<form onSubmit={this.onFormSubmit}>
	
	<div  className="ui fluid action input" style={{'marginTop':'15px','borderRadius':'15px','height':`${size}`,'boxShadow':'0px 0px 4px 0.3px rgba(166,166,166,1)'}}>
	<input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" style={{'fontSize':`${fsize}`,'backgroundColor':'white','color':'black' }} placeholder="Enter your email here" value={this.state.term} onChange={(e) => this.setState({ term : e.target.value})} required  />
	 <button  style={{'backgroundColor':'#11aba0','fontSize':`${fsize}`,'color':'white'}} className="ui button" >Search</button>
	</div>

	 
	 </form>
	 </div>
	);

}


}

export default Searchbar;
