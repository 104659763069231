import React from 'react';

import Post from './Post';
import Update from './Update';

const Postlist=({results,update})=>{

const final=
results.map((email)=>{
		return <Post result={email} />;
		});


return (<div >
		<div data-variation="small" data-tooltip="Data breach is a security incident in which confidential data is stolen" data-position="top center" style={{'fontSize':'30px','color':'red','fontWeight':'bold','textAlign':'center'}}>Breached Accounts</div>
		<hr style={{'align':'center','width':'8%','marginTop':'20px','marginBottom':'15px',  'border': '1px solid red','backgroundColor':'red','color':'red'}}></hr>

	<div >{final}</div>
	{update ===1? <Update />: null}
	</div>);





};

  


export default Postlist;