import React from 'react';

const Stat2=()=>
{

return(<div style={{'backgroundColor':'white'}} className="ui " >
	<div className='ui three float statistics'>
  <div className="statistic">
    <div style={{'fontSize':'28px'}} className="value">
      445+
    </div>
    <div className="label">
      Website Breached
    </div>

  </div>
  <div className="statistic">
    <div style={{'fontSize':'22px'}} className="value">
      4 Billion
    </div>
    <div className="label">
      Accounts Breached
    </div>
   
  </div>
  <div className="statistic">
    <div  style={{'fontSize':'28px'}} className="value">
       3 Million
    </div>
    <div className="label">
    Accounts Breached Everyday
    </div>

  </div>
  
</div>

</div>
);



};
export default Stat2;