import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client='ca-pub-1146420971576500'
          data-ad-slot='6302590529'
           data-full-width-responsive='true'
          data-ad-format='auto' />
    );
  }
}