import React from 'react';
import Adcomponent from './Adcomponent';

const Update=()=>{
return(<div>
	<div className="ui container" style={{'padding':'20px','boxShadow':'0px 0px 8px 1px rgba(166,166,166,1)','borderRadius': '10px','margin':'10px','backgroundColor':'#6696C8','color':'White', 'fontSize':'20px'}}> 
	<div style={{'marginBottom':'8px','fontSize':'25px','fontWeight':'bold'}}>If they know one, They know all.</div>
	<div style={{'marginBottom':'8px'}} >We suggest you secure all your accounts, Use our Directory feature</div>
	
	<a href="/directory.html" style={{'backgroundColor':'#32CD32','backgroundShadow':'0px 0px 8px 1px rgba(166,166,166,1)'}} rel="noopener noreferrer" target="_blank" class="ui left floated primary button">
				Visit Our Directory
          <i className="right chevron icon"></i>
        </a>
	</div>


	<br></br>
	<Adcomponent />
	</div>
);
};

export default Update;