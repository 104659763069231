import React from 'react';
import axios from 'axios';
import Postlist from './postlist';
import Nobreach from './Nobreach';
import Loader from './Loader';

import Searchbar from './searchbar'
import Result from './result';
import Stat from './Stat';
import BuyMeACoffee from './BuyMeACoffee'; 

class App extends React.Component {
state={result:[],noresult: '',load:false,update:0};



onSubmitform=async (term) =>
{
this.setState({load:true,});
	try{
	
		const response = await axios.get(`https://proxy.cors.sh/https://haveibeenpwned.com/api/v3/breachedaccount/${term}?truncateResponse=false`, 
			{
			headers: {
				'hibp-api-key': process.env.REACT_APP_HIBP_API_KEY,
				'User-Agent': 'Secureito.com',
				'x-cors-api-key': process.env.REACT_APP_CORS_API_KEY,
			}

});
	this.setState({load:false});


this.setState({result:response.data,noresult:0,update:1});
}
catch(e)
{
	this.setState({load:false,update:1});
	
	
	 this.setState({result: [],noresult:1});
}
}

render(){

return (<div>
	


	<div className="ui float container" style={{'backgroundColor':'F0FFF0'}}>
	<div>
	<Searchbar onSubmit={this.onSubmitform}/>

<div>
{this.state.load? <Loader /> : <Result len={this.state.result} /> }
   
	
	
	</div>
	</div>


	<br></br>
<div style={{'Position':'absolute'}}>
	
	
	<br></br>
	<BuyMeACoffee /> 



	{this.state.noresult === 1?<Nobreach update={this.state.update} /> :<Postlist results={this.state.result} update={this.state.update}  />}

    
 
	</div>
	<br></br>
	<Stat />
	<br></br>
	<br></br>
	
				
	</div>

	</div>
	
	);;
}


}

export default App;