import React from 'react';
import '../css/accordian.css';

const Accordion1 = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div className="accordion-wrapper">
      
      <div style={{'fontWeight':'bold'}}
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
         >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};
const Accordion = (props) => (
<div className="wrapper">
    <Accordion1  title="Learn What happened? ▼" >
		<div style={{'fontSize':'18px', 'color':'white','fontWeight':'normal' ,'fontFamily':'Roboto'}} 
  dangerouslySetInnerHTML={{
    __html: props.des
  }}></div>

    </Accordion1>
 
  </div>
);

export default Accordion;
