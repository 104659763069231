import React from 'react';
import Stat1 from './Stat1';
import Stat2 from './stat2'

const Stat=()=>
{
	const stats=window.innerWidth >800? <Stat2 />:<Stat1 />; 
return(<div  >{stats}</div>

);



};
export default Stat;